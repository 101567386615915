// extracted by mini-css-extract-plugin
export var background = "styles-module--background--ce43d";
export var blur = "styles-module--blur--b422a";
export var body = "styles-module--body--8cdf8";
export var contact = "styles-module--contact--af62d";
export var contactBody = "styles-module--contactBody--89c22";
export var contactBodyText = "styles-module--contactBodyText--20543";
export var container = "styles-module--container--4e72a";
export var desktop = "1340px";
export var ellipsis = "styles-module--ellipsis--eae01";
export var giant = "2200px";
export var hidden = "styles-module--hidden--9db31";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var layout = "styles-module--layout--57824";
export var link = "styles-module--link--1ed72";
export var mobile = "400px";
export var mobileWidget = "styles-module--mobileWidget--112ff";
export var scrollPadding = "styles-module--scrollPadding--fe005";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--b5cb6";
export var textWrapper = "styles-module--textWrapper--c1abe";
export var widget = "styles-module--widget--5cf2d";
export var widgetContainer = "styles-module--widgetContainer--9ce8d";
export var widgetWrapper = "styles-module--widgetWrapper--f26da";