import React, { Suspense, memo, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import {
  ATFWidget,
  IWidgetProps,
  LayoutProvider,
  TextAnimation
} from '@lam-agency/toolkit/components';
import Particles from './Particles';
import { useScroll, useWindowDimensions } from '@lam-agency/toolkit/hooks';

const ParticlesMemoized = memo(Particles); // Prevent particles reinitialising on scroll

interface IFields extends IWidgetProps {
  body: string;
  images: string[];
}
interface IProps {
  data: {
    fields: IFields;
  };
}

const FigmaCopyOnImage = ({
  data: {
    fields: { assetLinks, body, contactURL, quickLinks }
  }
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { scrollY } = useScroll();
  const { height } = useWindowDimensions();

  const hideParticles = scrollY > height * 1.5;

  return (
    <>
      <div
        ref={containerRef}
        className={cn(styles.container, { [styles.hidden]: hideParticles })}
      >
        <div className={styles.background}>
          <Canvas
            camera={{ position: [0, 0, 10], near: 0.1, far: 100, zoom: 14 }}
          >
            <Suspense fallback={null}>
              <ParticlesMemoized />
            </Suspense>
          </Canvas>
        </div>

        <LayoutProvider grid paddingX paddingY className={styles.layout}>
          <div className={styles.textWrapper}>
            <TextAnimation
              className={cn('d1')}
              text={body}
              animation={null}
              speed={0.03}
            />
          </div>

          <ATFWidget
            contactURL={contactURL}
            assetLinks={assetLinks}
            quickLinks={quickLinks}
          />
        </LayoutProvider>
      </div>
      <div aria-hidden className={styles.scrollPadding} />
    </>
  );
};

export default FigmaCopyOnImage;
